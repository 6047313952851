import React from 'react';
import { useStoryblok } from '../lib/storyblok';

import Layout from './../components/layout';
import Meta from './../components/meta';
import DynamicComponent from '../components/dynamicComponent';
import { Header } from './../components';

const AnswerPage = ({ pageContext, location }) => {
	let story = pageContext.story;
	story = useStoryblok(story, location);

	const components = story.content.body.map(blok => {
		if(blok.component === 'answer-multiple-choice') {

			return (
				<DynamicComponent
					key={blok._uid}
					blok={blok}
					slug={story.slug}
					fullSlug={story.full_slug}
					questionElements={location.state}
				/>
			);
		}
		return (
			<DynamicComponent key={blok._uid} blok={blok} slug={story.slug} fullSlug={story.full_slug} />
		);
	});

	return (
		<Layout>
			<Meta />
			<Header/>
			{components}
		</Layout>
	);
};

export default AnswerPage;
